/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap-grid.min.css';
@import '~ngx-toastr/toastr.css';

html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', sans-serif; }

// #region table
table {
  width: 100%;
}

.mat-header-cell {
  text-transform: uppercase;
}

.mat-column-actions {
  width: 40px;
}
// #endregion

// #region forms
.mat-form-field {
  width: 100%;
}
// #endregion

// #region toolbar
.mat-toolbar {
  button {
    margin-left: 12px;
  }

  .fill-remaining-space {
    /* This fills the remaining space, by using flexbox.
       Every toolbar row uses a flexbox row layout. */
    flex: 1 1 auto;
  }

  &.main-toolbar {
    margin-bottom: 12px;
  }
}
// #endregion

// #region filter
.filter-area {
  margin-bottom: 20px;
  padding: 20px;
  background-color: whitesmoke;

  button {
    margin-right: 6px;
  }
}
// #endregion
